@font-face {
  font-family: 'Wallpoet Regular';
  src: url('./assets/NeueMachina-Bold.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Wallpoet Regular';
  src: url('./assets/NeueMachina-Bold.ttf');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Wallpoet Regular';
  src: url('./assets/NeueMachina-Regular.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Neue Haas Grotesk Display Pro';
  src: url('./assets/Neue Haas Grotesk Display Pro 55 Roman.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Wallpoet Regular';
  src: url('./assets/NeueMachina-Ultrabold.ttf');
  font-weight: 700;
  font-style: normal;
}

html * {
  font-family: 'Wallpoet Regular', sans-serif;
  box-sizing: border-box;
}

html, body {
  background-color: #000000 !important;
  margin: 0;
}

a{
  text-decoration: none;
}

input{
  font-size: 16px;
  line-height: 22px;
}

::-webkit-scrollbar {
  width: 9px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background-color: #080809 !important;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: #CECECE;
  height: 82px;
}

.MuiBackdrop-root:not(.MuiPopover-root) {
  top: 48px !important;
  backdrop-filter: blur(10px);
}

.mantine-Modal-inner{
  padding: 16px 16px 0;
  align-items: center;
}


.mantine-Paper-root {
  width: auto;
}

.MuiModal-root:not(.MuiPopover-root) {
  backdrop-filter: blur(10px);
  top: 48px !important;
}

.MuiTabs-scroller {
  height: 44px !important;
  letter-spacing: normal;
}

.MuiBackdrop-invisible {
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0) !important;
}

#notification-runtime{
  bottom: 24px !important;
}

.aries-notification {
  position: relative;

  .heading-icon{
    margin-right: 12px;
  }

  .notification-body {
    max-width: 256px;

    .title-container{
      display: inline;
      margin-right: 4px;

      .title {
        display: inline !important;
        font-size: 16px;
      }
    }

    .notification-close {
      position: absolute;
      top: 50%;
      right: 16px;
      width: 34px;
      height: 34px !important;
      transform: translate(0, -50%);
    }

    .content {
      margin-top: 0;
      font-size: 16px;
    }

    .time {
      display: none;
    }
  }
}